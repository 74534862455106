
@import "./reset.css";

@font-face {
  font-family: 'Moontimefont';
  src:  url('../images/MoonTime-Regular-www.Dfonts.org/MoonTime-Regular.otf') format('woff2');
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #fff;
  background-image: url(../images/texturewallpaper2.jpeg);
  background-repeat: repeat;
  background-position: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

