
.photos {
	display: block;
	width: 100%;
	margin: 20px 0 0;
}

.photos > span {
	display: block;
	font-family: verdana;
	font-size: 12px;
	font-weight: bold;
	color: #999;
}

.photos > div {
	display: block;
	width: 200px;
	outline: 0;
	border: 0;
	font-family: 'SUNDAYS';
	font-weight: bold;
	color: rgba(146,125,92,0.92);
}

